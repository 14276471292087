import Header from './Header';
import { useSiteInfo } from '@/context/SiteInfoContext';
import StylesContainer from '../utility/StylesContainer';
import dynamic from 'next/dynamic';

const Footer = dynamic(() => import('./Footer'), {
  ssr: false, // This disables server-side rendering for this component
});

const Mainlayout = ({ children, ...pageProps }) => {
  const { siteInfo } = useSiteInfo();
  if (!siteInfo) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <StylesContainer siteInfo={siteInfo} />
      <Header pageProps={pageProps} />
      <main className="main">{children}</main>
      <Footer />
    </>
  );
};

export default Mainlayout;
