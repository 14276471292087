import { useEffect, useState } from 'react';

import Topbar from './Topbar';
import SitesService from 'services/SitesService';
import { useCategory } from '@/context/CategoryContext';

import dynamic from 'next/dynamic';
const AnnouncementBar = dynamic(() => import('./Announcementbar'), {
  ssr: false,
});

export default function Header({ pageProps }) {
  const siteInfo = pageProps.pageProps.siteInfo;
  //const categories = pageProps.pageProps.categories;

  const { categories = [], loading, error } = useCategory();
  const [announcementBanner, setAnnouncementBanner] = useState(false);
  const [announcementText, setAnnouncementText] = useState('');

  const fetchAnnouncementDetails = async () => {
    try {
      const response = await SitesService.getSiteAnnoucement(
        siteInfo.domain_name,
      );
      const homepage = response?.siteInfo?.Sites[0]?.homepage;
      if (homepage) {
        setAnnouncementBanner(homepage.announcement_banner);
        setAnnouncementText(homepage.announcement_text);
      }
    } catch (err) {
      console.error('Error occurred while fetching announcement details', err);
    }
  };

  useEffect(() => {
    fetchAnnouncementDetails();
  }, []);

  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <Topbar siteInfo={siteInfo} categories={categories} />
            </div>
          </div>
        </div>
      </header>
      {announcementBanner ? (
        <AnnouncementBar announcementText={announcementText} />
      ) : null}
    </>
  );
}
