import { useSession } from 'next-auth/react';
import { Nav, Navbar, Button } from 'react-bootstrap';
import Authpopup from './Authpopup';
import MdcCloseCircleOutline from '@meronex/icons/mdc/MdcCloseCircleOutline';
import MobileMenu from './MobileMenu';
import Logo from './Logo';
import NavItem from './NavItem';
import React from 'react';
import { ReactSVG } from 'react-svg';
import MenuIcon from '@/images/menuicon.svg';
import { sign_up } from 'utils/gtag';
import { useEffect, useRef } from 'react';
export default function Topbar({ categories }) {
  const { status, data: session, update } = useSession();

  const signUpFiredRef = useRef(false);
  useEffect(() => {
    // Define an async function inside the effect
    const checkIfNewAndUpdate = async () => {
      if (session?.user?.isNew && !signUpFiredRef.current) {
        signUpFiredRef.current = true;

        // Fire GTM sign_up event
        sign_up();

        // Await your update call
        await update({
          ...session,
          user: {
            ...session.user,
            isNew: false,
          },
        });

        // Optionally show a welcome modal, etc.
      }
    };

    // Call the async function
    checkIfNewAndUpdate();
  }, [session, update]);
  const sortedCategories = categories?.sort((a, b) => {
    if (a.sort === null) return 1;
    else return a.sort - b.sort;
  });

  const toggleMobileMenu = async () => {
    var toggleBox = document.getElementById('basic-navbar-nav');
    var mobilemenuoverlay = document.getElementById('mobilemenuoverlay');
    toggleBox.classList.toggle('open');
    mobilemenuoverlay.classList.toggle('open');
    if (document) document.body.classList.add('no-scroll');
  };

  const hideMobileMenu = async () => {
    var toggleBox = document.getElementById('basic-navbar-nav');
    var mobilemenuoverlay = document.getElementById('mobilemenuoverlay');
    toggleBox.classList.remove('open');
    mobilemenuoverlay.classList.remove('open');
    if (document) document.body.classList.remove('no-scroll');
  };

  return (
    <>
      <Navbar expand="lg">
        <Logo />

        <Button className="mobilemenutoggle" onClick={() => toggleMobileMenu()}>
          <ReactSVG src={MenuIcon.src} />
        </Button>

        <div id="mobilemenuoverlay" onClick={() => hideMobileMenu()}></div>

        <Navbar.Collapse id="basic-navbar-nav">
          <div id="mobilemenuclose" onClick={() => hideMobileMenu()}>
            <MdcCloseCircleOutline />
          </div>

          <Authpopup hideMobileMenu={hideMobileMenu} />

          {status === 'authenticated' && (
            <MobileMenu hideMobileMenu={hideMobileMenu} />
          )}

          <Nav className="m-auto">
            {sortedCategories?.length > 0 &&
              sortedCategories.map((_category) => (
                <React.Fragment key={_category.cat_id}>
                  {_category.show_on_top_nav && (
                    <NavItem
                      category={_category}
                      key={_category.cat_id}
                      hideMobileMenu={hideMobileMenu}
                    />
                  )}
                </React.Fragment>
              ))}

            {(!sortedCategories || sortedCategories.length === 0) &&
              [0, 1, 2, 3, 4, 5].map((i) => (
                <Nav.Item key={i} className="bp5-skeleton category-skeleton">
                  Loading...
                </Nav.Item>
              ))}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
